<div class="grid-container">
  <div class="info" id="my-element">
    <div class="title">CHECKOUT</div>
    <div class="address">
      <div
        class="customer-info"
        [ngClass]="{ 'invalid-info': customerInfoValidity.all == false }"
      >
        <label
          class="heading"
          (click)="createAddressForm(); scrollToTop()"
          title="Click to edit customer information"
          (click)="openCustomerInfoModal()"
        >
          Customer Information
          <a>
            <mat-icon aria-hidden="false" [inline]="true" aria-label="Edit icon"
              >edit</mat-icon
            >
          </a>
        </label>
        <div class="same-as-billing"></div>
        <!-- <p class="contact-name address-content"> {{user?.firstName + " " + user?.lastName}} </p> -->
        <ng-container *ngIf="user">
          <p class="user-info-content" *ngIf="user?.firstName">
            {{ user?.firstName + " " + (user?.lastName || "") }}
          </p>
          <p
            class="user-info-content no-data-label"
            *ngIf="!user?.firstName"
            [ngClass]="{ 'invalid-field': customerInfoValidity.name == false }"
            (click)="createAddressForm(); scrollToTop()"
            (click)="openCustomerInfoModal()"
          >
            [Enter name]
          </p>
          <p class="user-info-content">{{ user?.email }}</p>
          <p class="user-info-content" *ngIf="user?.phoneNumber">
            {{ user?.phoneNumber }}
          </p>
          <p
            class="user-info-content no-data-label"
            *ngIf="!user?.phoneNumber"
            [ngClass]="{ 'invalid-field': customerInfoValidity.phone == false }"
            (click)="createAddressForm(); scrollToTop()"
            (click)="openCustomerInfoModal()"
          >
            [Enter phone number]
          </p>
        </ng-container>
        <!-- <ng-template [ngIf]="paymentMethod == 3 && (!billingAddress || !billingAddress?.address)">
          <a href="#" class="link m-3" data-toggle="modal" data-target="#billingAddressModal">Enter billing address</a>
        </ng-template> -->
        <!-- <ng-template
          [ngIf]="(paymentMethod == 1 || paymentMethod == 2) && (!billingAddress || !billingAddress?.address)">
          <p class="m-3">Select Organization</p>
        </ng-template> -->
      </div>
      <div
        class="billing-address"
        [ngClass]="{ 'invalid-info': invalidBillingAddress == true }"
      >
        <label
          class="heading"
          (click)="createAddressForm(); scrollToTop()"
          title="Click to edit billing address"
          data-toggle="modal"
          data-target="#billingAddressModal"
        >
          Billing Address
          <a *ngIf="billingAddress?.isEditable">
            <mat-icon aria-hidden="false" [inline]="true" aria-label="Edit icon"
              >edit</mat-icon
            >
          </a>
        </label>
        <div class="same-as-billing"></div>
        <!-- <p class="contact-name address-content"> {{user?.firstName + " " + user?.lastName}} </p> -->
        <ng-template [ngIf]="billingAddress?.address != ''">
          <p class="address-content">{{ billingAddress?.orgName }}</p>
          <p class="address-content">{{ billingAddress?.address }}</p>
          <p class="address-content">{{ billingAddress?.address1 }}</p>
          <p class="address-content">{{ billingAddress?.city }}</p>
          <p class="address-content">{{ billingAddress?.state }}</p>
          <p class="address-content">{{ billingAddress?.zipCode }}</p>
        </ng-template>
        <ng-template
          [ngIf]="
            paymentMethod == 3 && (!billingAddress || !billingAddress?.address)
          "
        >
          <p
            class="m-3 invalid-field no-data-label"
            data-toggle="modal"
            data-target="#billingAddressModal"
          >
            [Enter billing address]
          </p>
        </ng-template>
        <ng-template
          [ngIf]="
            (paymentMethod == 1 || paymentMethod == 2) &&
            (!billingAddress || !billingAddress?.address)
          "
        >
          <p class="m-3">Select Organization</p>
        </ng-template>
      </div>
      <!-- <ng-template [ngIf]="(teamStore?.shippingPreference === 1 || purchaseType != 3) && paymentMethod === 3">
        <mat-checkbox (change)="updateShippingAddressAsBillingAddress($event.checked)" class="m-2 same-as-billing-sm"
          [checked]="user?.isSameAsBillingAddress">
          <mat-hint>Same as billing address</mat-hint>
        </mat-checkbox>
      </ng-template> -->
      <div
        class="shipping-address"
        [ngClass]="{ 'invalid-info': invalidShippingAddress == true }"
        *ngIf="!teamStore || teamStore?.shippingPreference == 1"
      >
        <label
          class="heading"
          (click)="createAddressForm(); scrollToTop()"
          *ngIf="!shippingAddress?.isEditable"
        >
          Shipping Address
        </label>
        <label
          class="heading"
          (click)="createAddressForm(); scrollToTop()"
          title="Click to edit shipping address"
          *ngIf="shippingAddress?.isEditable"
          data-toggle="modal"
          data-target="#shippingAddressModal"
        >
          Shipping Address
          <a>
            <mat-icon aria-hidden="false" [inline]="true" aria-label="Edit icon"
              >edit</mat-icon
            >
          </a>
        </label>
        <div class="same-as-billing">
          <mat-checkbox
            [(ngModel)]="isShippingAddressSameAsBillingAddress"
            [disabled]="!this.billingAddress.address"
            (change)="updateShippingAddressAsBillingAddress($event.checked)"
          >
            <mat-hint>Same as billing address</mat-hint>
          </mat-checkbox>
        </div>
        <ng-template [ngIf]="shippingAddress?.address != ''">
          <p class="address-content">{{ shippingAddress?.orgName }}</p>
          <p class="address-content">{{ shippingAddress?.address }}</p>
          <p class="address-content">{{ shippingAddress?.address1 }}</p>
          <p class="address-content">{{ shippingAddress?.city }}</p>
          <p class="address-content">{{ shippingAddress?.state }}</p>
          <p class="address-content">{{ shippingAddress?.zipCode }}</p>
        </ng-template>
        <ng-template
          [ngIf]="
            paymentMethod == 3 &&
            (!shippingAddress || !shippingAddress?.address)
          "
        >
          <p
            class="invalid-field m-3 no-data-label"
            data-toggle="modal"
            data-target="#shippingAddressModal"
          >
            [Enter shipping address]
          </p>
        </ng-template>
        <ng-template
          [ngIf]="
            (paymentMethod == 1 || paymentMethod == 2) &&
            (!shippingAddress || !shippingAddress?.address)
          "
        >
          <p class="m-3">Select Organization</p>
        </ng-template>
      </div>
      <!-- <ng-template [ngIf]="(teamStore?.shippingPreference === 1 || purchaseType != 3) && paymentMethod === 3">
        <mat-checkbox (change)="updateShippingAddressAsBillingAddress($event.checked)" class="m-2 same-as-billing-md"
          [checked]="user?.isSameAsBillingAddress">
          <mat-hint>Same as billing address</mat-hint>
        </mat-checkbox>
      </ng-template> -->
      <!-- <ng-template
        [ngIf]="
          (teamStore?.shippingPreference === 1 || purchaseType != 3) &&
          paymentMethod !== 3
        "
      >
        <mat-icon
          data-toggle="tooltip"
          data-placement="bottom"
          class="help-icon"
          color="primary"
          title="Shipping and Billing address will be the same as &#10;customer's Organization address for pay by PO/Invoice"
        >
          info
        </mat-icon>
      </ng-template> -->
      <div class="invoice-total">
        <label class="heading"> Invoice Total  </label>
        <p class="total-amount">
          ${{
            (offerApplied ? subtotalPrice : checkoutObj.totalPrice) +
              (getShipAmount() || 0) +
              taxObject?.totalTax | number : "1.2-2"
          }}
        </p>
      </div>
    </div>
    <div class="items">
      <table class="table">
        <thead class="table-header">
          <tr>
            <th>Description</th>
            <th class="amount-column">Qty</th>
            <th class="amount-column">Amount</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of checkoutObj?.cartItemsInfo; let i=index;">
            <tr>
              <td class="align-left "> 
                <div id="accordion{{i}}" class="accordion">
                  <div class="accordion-header" [id]="'heading' + i" (click)="toggleAccordion(i)">
                    <h5 class="mb-0" class="forLabel">
                      <span class="triangle" [class.rotate]="rotateArrow[i]"></span> 
                      <label class="btn">
                        {{ item.displayName ? item.displayName  : item.productName }}    
                      </label> 
                    </h5>
                  </div>
                  <div [id]="'collapse' + i" class="collapse" [class.show]="rotateArrow[i]" [attr.aria-labelledby]="'heading' + i">
                    <div class="card-body">
                      <table class="prod-info">
                        <tr class="forLabel">
                          <th style="color: black;">Color :</th>
                          <td colspan="5">{{item?.cartItemGrids[0]?.color}}</td>
                        </tr>
                        <tr class="forLabel">
                          <th style="color: black;">Sizes :</th>
                          <td>
                            <ng-container *ngFor="let p of item?.cartItemGrids">
                              <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">{{p.size}}</div>
                            </ng-container>
                          </td>
                          <td class="numbers">
                            <ng-container *ngFor="let p of item?.cartItemGrids">
                              <div *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">({{p.quantity}})</div>
                            </ng-container>
                          </td>
                          <td class="numbers forLabel">
                            <ng-container *ngFor="let p of item?.cartItemGrids">
                              <div [style.textDecoration]="isStrikethroughSub(p) ? 'line-through' : 'none'" *ngIf="p.quantity && p.quantity>0" class="w-100 p-1">{{'$'+ p.totalPrice}}</div>
                            </ng-container>
                          </td>
                          <td class="numbers forLabel">
                            <ng-container *ngFor="let p of item?.cartItemGrids">
                              <div *ngIf="!isStrikethroughSub(p) && offerApplied" class="w-100 p-1">-</div>
                              <div *ngIf="isStrikethroughSub(p)" class="w-100 p-1">{{'$'+ isStrikethroughSubForDiscountAmount(p)}}</div>
                            </ng-container>
                          </td>
                        </tr> 
                      </table>
                    </div>
                  </div>
                </div>
              </td>

              <td class="numbers">&nbsp; &nbsp; {{ item.quantity }}</td>
              <td class="numbers">
                <span *ngIf="isStrikethrough(item)">${{ getDiscountedPrice(item) | number : '1.2-2' }}</span> &nbsp; &nbsp;
                <span [style.textDecoration]="isStrikethrough(item) ? 'line-through' : 'none'">${{ item.totalPrice | number : "1.2-2" }} </span> 
              </td>
            </tr> 
          </ng-container>
        </tbody>
      </table>
    </div>
    
    <div class="items2">
      <table class="table"> 
        <div class="items">

          <form [formGroup]="form">
            <div *ngFor="let controlData of formData">
              <div [ngSwitch]="controlData?.customFieldType"> 
                <div *ngSwitchCase="0">
                  <label>{{ controlData?.fieldLabel }} <span *ngIf="controlData?.isMandatory" [ngClass]="{ 'invalid-info': form.get(controlData?.fieldLabel)?.hasError('required') }">*</span></label>
                  <input class="form-control"   [ngClass]="{ 'invalid-infomn': form.get(controlData?.fieldLabel)?.touched && form.get(controlData?.fieldLabel)?.hasError('required') }"
                    type="text"
                    [formControlName]="controlData?.fieldLabel" placeholder="{{controlData?.fieldInstruction }}"
                  />
                  <!-- <p style="margin-top: 1rem" class="message" *ngIf="form.get(controlData.fieldLabel).hasError('required')">  {{ controlData.fieldInstruction }} </p> -->
                </div>
                 
    
                <div *ngSwitchCase="1">
                  <label>{{ controlData?.fieldLabel }} <span *ngIf="controlData?.isMandatory" [ngClass]="{ 'invalid-info': form.get(controlData?.fieldLabel)?.hasError('required') }">*</span></label>
                  <select class="form-control" [formControlName]="controlData?.fieldLabel" [ngClass]="{ 'invalid-infomn': form.get(controlData?.fieldLabel)?.touched && form.get(controlData?.fieldLabel)?.hasError('required') }">
                    <option value="" disabled>{{controlData?.fieldInstruction}}</option>
                    <option
                      *ngFor="let choice of controlData?.multipleChoices"
                      [value]="choice?.value"
                    >
                      {{ choice?.value }}
                    </option>
                  </select>
                  <!-- <p style="margin-top: 1rem" *ngIf="form.get(controlData.fieldLabel).hasError('required')" class="message" > {{ controlData.fieldInstruction }} </p> -->
                </div>
                <br/>
    
              </div>
            </div>
             
          </form>
        </div>
      </table>
    </div>  
    <div class="shipping-method">
      <select
        class="form-control"
        (change)="selectShippingMethod($event.target.value)"
        [ngClass]="{ 'invalid-info': invalidShippingMethod == true }"
        [(ngModel)]="defaultSelection"
      >
        <option *ngIf="defaultSelection==''" value="" selected >-- Select Shipping Method --</option>
        <option *ngFor="let method of shippingMethods" [value]="method.id">
          {{ method.name }}
        </option>
      </select>
      <p class="message" *ngIf="teamStore?.shippingPreference == 1">
        Orders will be shipped directly to you.
      </p>
      <p class="message" *ngIf="teamStore?.shippingPreference == 2">
        Your store admin has elected to have all products shipped directly to
        them for distribution
      </p>
      <p class="message" *ngIf="teamStore?.shippingPreference == 3">
        Your store admin has elected to pick up all products for distribution
      </p>
    </div>
    <div class="amounts">
      <table class="ml-2 mr-2 coupon-table">
        <tr>
          <td style="position: relative">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Enter Coupon code</mat-label>
              <input
                type="text"
                matInput
                [formControl]="addCoupon"
                [readonly]="offerApplied"
              />
              <mat-error>{{invalidCouponMessage}}</mat-error>
            </mat-form-field>
            <svg
              *ngIf="offerApplied"
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </td>
          <td *ngIf="!offerApplied">
            <button
              class="ml-3 mb-3"
              mat-raised-button
              color="primary"
              (click)="discountCoupon()"
            >
              Apply Coupon
            </button>
          </td>
          <td *ngIf="offerApplied">
            <button
              class="ml-3 mb-3"
              mat-raised-button
              color="primary"
              (click)="restCoupon()"
            >
              Reset Coupon
            </button>
          </td>
        </tr>
      </table>
      <table class="table">
        <tr>
          <td>Sub Total</td>
          <td style="text-align: right">
            ${{ checkoutObj?.totalPrice | number : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="offerApplied">
          <td>Discount Code</td>
          <td style="text-align: right">
            - ${{ discountAmount | number : "1.2-2" }}
          </td>
        </tr>
        <tr *ngIf="offerApplied">
          <td>Sub Total After Discount</td>
          <td style="text-align: right">
            ${{ subtotalPrice | number : "1.2-2" }}
          </td>
        </tr>
       
        <tr>
          <td>Shipping Charges</td>
          <td style="text-align: right">
            <span *ngIf="selectedShippingMethod?.price && selectedShippingMethod?.id==CouponApplyShippingMethodId">${{ shippingAfterCouponApplied | number : "1.2-2" }}</span> &nbsp;&nbsp;
            <span [style.textDecoration]="selectedShippingMethod?.price && selectedShippingMethod?.id==CouponApplyShippingMethodId ? 'line-through' : 'none'">${{ selectedShippingMethod?.price + 0 | number : "1.2-2" }}</span>
          </td>
        </tr>
        <!-- <tr *ngIf="selectedShippingMethod?.price && selectedShippingMethod?.id==CouponApplyShippingMethodId">
          <td>Shipping Charges after discount</td>
          <td style="text-align: right">
            ${{ shippingAfterCouponApplied | number : "1.2-2" }}
          </td>
        </tr> -->
        <tr>
          <td>Sales Tax</td>
          <td style="text-align: right">
            ${{ taxObject?.totalTax | number : "1.2-2" }}
          </td>
        </tr>
        <tr class="total-amount-label">
          <td>
            <label class="heading"> Invoice Total  </label>
          </td>
          <td style="text-align: right">
            <p class="total-amount">
              ${{
                (offerApplied ? subtotalPrice : checkoutObj.totalPrice) +
                  (getShipAmount() || 0) +
                  taxObject?.totalTax | number : "1.2-2"
              }}
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="payment">
    <div class="title">PAYMENT OPTIONS</div>
    <div class="payment-methods">
      <div class="form-check" *ngIf="purchaseType!=3">
        <label style="margin-left: -3%;">Select Pay </label><br>
        <label for="pm_3" class="form-check-label">
          <input
            class="form-check-input position-static"
            type="radio"
            name="paymentMethod"
            [value]="3"
            id="pm_3"
            [(ngModel)]="paymentMethod"
            (change)="paymentMethod=3;disableButtons();getClientToken()"
          />
          Pay by Credit Card
        </label><br>
        <label for="pm_2" class="form-check-label">
          <input
            class="form-check-input position-static"
            type="radio"
            name="paymentMethod"
            [value]="2"
            id="pm_2"
            [(ngModel)]="paymentMethod"
            (change)="paymentMethod=2;enableButtons()"
          />
          Pay by Purchase Order
        </label>
      </div>
      
      <!-- <div class="form-check" *ngIf="purchaseType !== 3 && user?.isCreditEnabled == 1">
        <input class="form-check-input position-static" type="radio" name="paymentMethod" 
        [value]="1" id="pm_1" [(ngModel)]="paymentMethod" (change)="selectOrganization();">
        <label for="pm_1"> Pay by Invoice </label>
      </div> -->
    </div>
    <div class="payment-info">
      <div class="pay-by-credit-card">
        <div class="card-logo" *ngIf="paymentMethod==3">
          <!-- <img src="{{ src }}" *ngFor="let src of cardLogos" /> -->
          <a href="https://www.braintreegateway.com/merchants/hndc4jr4yxm9ygpt/verified" target="_blank">
            <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"  style="width: 300px;" border="0"/>
            </a>
        </div>
        <div class="card-details mt-3">
          <div *ngIf="paymentMethod==3" id="dropin-container"></div>
          <div *ngIf="paymentMethod==2">
            <div class="form-container">
              <div class="custom-form-container">
                <form [formGroup]="purchaseOrderForm" >
                  <div class="custom-form-group">
                    <label for="organizationId">Organization</label>
                    <select id="organizationId" formControlName="organizationId" class="custom-form-control" (change)="onOrganizationSelect()" >
                      <option value="" disabled>Select an organization</option>
                      <option [value]="'Add'">Add Organization</option>
                      <option *ngFor="let org of this.user?.organizations" [value]="org.id">{{ org.name }}</option>
                    </select>
                    <div *ngIf="organizationId.invalid && organizationId.touched" class="custom-error">
                      Organization is required.
                    </div>
                  </div>
              
                  <div class="custom-form-group">
                    <label for="referenceId">Purchase Order Number</label>
                    <input id="referenceId" type="text" formControlName="referenceId" class="custom-form-control">
                    <div *ngIf="referenceId.invalid && referenceId.touched" class="custom-error">
                      <div *ngIf="referenceId.errors?.required">Purchase Order Number is required.</div>
                      <div *ngIf="referenceId.errors?.minlength">Purchase Order Number must be at least 3 characters long.</div>
                      <span *ngIf="purchaseOrderForm.get('referenceId').hasError('alphaNumeric')">Purchase Order Number must be alphanumeric.</span>
                      <span *ngIf="purchaseOrderForm.get('referenceId').hasError('maxlength')">Purchase Order Number cannot exceed 25 characters.</span>
                      <span *ngIf="purchaseOrderForm.get('referenceId').hasError('minLength')">Purchase Order Number required minimum 3 characters.</span>

                    </div>
                  </div>
              
                  <div class="custom-form-group">
                    <label for="attachment">Attachment</label>
                    <input  accept=".jpg,.jpeg,.png,.pdf"  formControlName="attachment" #customArtUpload id="attachment" type="file" id="size-chart-file"  class="custom-form-control"
                    (change)="onFileSelect($event)">
                    
                    <div *ngIf="attachment.invalid && attachment.touched" class="custom-error">
                      Attachment is required.
                    </div>
                  </div>
                  <small class="po-note" style="font-style: italic; font-weight: lighter;">
                    Please ensure Purchase Order is on an organization letterhead and signed by an officer.
                  </small>
                </form>
              </div>
              
            </div>
            
          </div>
          <div class="w-100 text-center">
            <button id="payment-request-button" style="display: none"></button>
            <button
              class="btn btn-outline-dark btn-lg m-2 mb-0"
              (click)="backToCart()"
              id="back-to-cart-btn"
            >
              Back to cart
            </button>
            <button
              class="btn btn-primary btn-lg m-2 mb-0"
              (click)="checkout()"
              id="pay-now-btn"
              [disabled]="isCheckoutDisabled()"
            >
              <!-- Pay with <span id="card-brand">Card</span> -->
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Billing Address Modal -->
<div
  class="modal fade"
  id="billingAddressModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Billing Address</h4>
        <button type="button" class="close" (click)="closeModal()">
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form
          [formGroup]="updatedBillingAddress"
          id="updatedBillingAddressForm"
          class="needs-validation"
          novalidate
        >
          <input
            type="text"
            class="form-control my-2"
            placeholder="Address"
            formControlName="billingAddress"
            required
          />
          <input
            type="text"
            class="form-control my-2"
            placeholder="Address 2"
            formControlName="billingAddress1"
          />
          <input
            type="text"
            class="form-control my-2"
            placeholder="City"
            formControlName="billingCity"
            required
          />
          <select
            class="form-control w-100 my-2"
            placeholder="State"
            formControlName="billingState"
            required
          >
            <option hidden disabled selected [value]="-1">State</option>
            <option *ngFor="let state of states" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
          <input
            type="text"
            class="form-control my-2"
            placeholder="Zip Code"
            formControlName="billingZipCode"
            required
          />
          <br /><br />
          <mat-checkbox
            class="my-3"
            formControlName="updateProfile"
            *ngIf="user?.emailConfirmed"
          >
            <mat-hint>Update billing address in profile</mat-hint>
          </mat-checkbox>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateBillingAddress()"
        >
          Update
        </button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Shipping Address Modal -->
<div
  class="modal fade"
  id="shippingAddressModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Shipping Address</h4>
        <button type="button" class="close" (click)="closeModal()">
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form
          [formGroup]="updatedShippingAddress"
          id="updatedShippingAddressForm"
          class="needs-validation"
          novalidate
        >
          <!-- <div class="w-100 text-right">
            <mat-checkbox (change)="updateShippingAddressAsBillingAddress($event.checked)" class="m-2">
              <mat-hint>Same as billing address</mat-hint>
            </mat-checkbox>
          </div> -->
          <input
            type="text"
            class="form-control my-2"
            placeholder="Address 1"
            formControlName="shippingAddress"
            required
          />
          <input
            type="text"
            class="form-control my-2"
            placeholder="Address 2"
            formControlName="shippingAddress1"
          />
          <input
            type="text"
            class="form-control my-2"
            placeholder="City"
            formControlName="shippingCity"
            required
          />
          <select
            class="form-control w-100 my-2"
            placeholder="State"
            formControlName="shippingState"
            required
          >
            <option hidden disabled selected [value]="-1">State</option>
            <option *ngFor="let state of states" [value]="state.name">
              {{ state.name }}
            </option>
          </select>
          <input
            type="text"
            class="form-control my-2"
            placeholder="Zip Code"
            formControlName="shippingZipCode"
            required
          />
          <br /><br />
          <mat-checkbox
            class="my-3"
            formControlName="updateProfile"
            *ngIf="user?.emailConfirmed"
          >
            <mat-hint>Update shipping address in profile</mat-hint>
          </mat-checkbox>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateShippingAddress()"
        >
          Update
        </button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="customerInfoModal"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Customer Information</h4>
        <button type="button" class="close" (click)="closeModal()">
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form
          [formGroup]="updatedCustomerInformation"
          id="updatedCustomerInformationForm"
          class="needs-validation row"
          novalidate
        >
          <div class="col-6 p-2">
            <input
              type="text"
              class="form-control"
              placeholder="First Name"
              formControlName="firstName"
              [ngClass]="{
                'is-invalid':
                  updatedCustomerInformation?.get('firstName')?.errors &&
                  updatedCustomerInformation?.get('firstName').touched,
                'is-valid':
                  !updatedCustomerInformation?.get('firstName')?.errors &&
                  updatedCustomerInformation?.get('firstName').touched
              }"
              (blur)="trimName()"
            />
          </div>
          <div class="col-6 p-2">
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              formControlName="lastName"
              [ngClass]="{
                'is-invalid':
                  updatedCustomerInformation?.get('lastName')?.errors &&
                  updatedCustomerInformation?.get('lastName').touched,
                'is-valid':
                  !updatedCustomerInformation?.get('lastName')?.errors &&
                  updatedCustomerInformation?.get('lastName').touched
              }"
              (blur)="trimName()"
            />
          </div>

          <input
            type="number"
            class="form-control m-2 phone-number-field"
            placeholder="Phone"
            [ngClass]="{
              'is-invalid':
                updatedCustomerInformation?.get('phoneNumber')?.errors &&
                updatedCustomerInformation?.get('phoneNumber').touched,
              'is-valid':
                !updatedCustomerInformation?.get('phoneNumber')?.errors &&
                updatedCustomerInformation?.get('phoneNumber').touched
            }"
            formControlName="phoneNumber"
            pattern="{{ phonePattern }}"
            required
          />
          <input
            type="text"
            class="form-control m-2"
            placeholder="Email"
            formControlName="email"
            readonly
          />
          <!-- <mat-checkbox class="my-3" formControlName="updateProfile">
            <mat-hint>Update customer information in profile</mat-hint>
          </mat-checkbox> -->
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateCustomerInfo()"
        >
          Update
        </button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="organizationForm" class="modal fade" id="addOrgModal" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <mat-label>Add Organization</mat-label>
              <button type="button" class="close" (click)="closeOrganiztionModal()">
                  &times;
              </button>
          </div>
          <div class="modal-body">
              <form [formGroup]="organizationForm" novalidate class="form-group" id="organizationForm"> 
                  <input type="text" class="form-control m-2" formControlName="name" placeholder="Organization Name"
                      required />
                  <input type="text" class="form-control m-2" formControlName="address" placeholder="Address 1"
                      required />
                  <input type="text" class="form-control m-2" formControlName="address1" placeholder="Address 2" />
                  <input type="text" class="form-control m-2" formControlName="city" placeholder="City" required />
                  <select class="form-control m-2" formControlName="state" name="State" required>
                      <option value="" disabled selected hidden>State</option>
                      <option *ngFor="let item of states" [value]="item.id">
                          {{ item.name }}
                      </option>
                  </select>
                  <input type="text" class="form-control m-2" formControlName="zipCode" placeholder="Zip Code" required
                      (input)="onZipCodeInput($event)" />
                  <button type="button" class="btn btn-primary m-2 float-right" (click)="createNewOrganization()">
                      Add Organization
                  </button>
              </form>
          </div>
      </div>
  </div>
</div>