import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss']
})
export class PdfModalComponent implements OnInit {
  @Input() storeData: any;
  constructor(public dialogRef: MatDialogRef<PdfModalComponent>,
    private sharedService: SharedService, private storeService: StoreService, private commonService: CommonService
  ) { }


  ngOnInit(): void {
    console.log(this.storeData, "storeData in modal");

  }

  sendMail() {

  }

  closeModal() {
    this.dialogRef.close(); // Close the modal
  }

  downloadFlyer(storeId: number): void {
    this.commonService.toggleLoading(true)
    this.storeService.downloadFlyerPdf(storeId).pipe(
    ).subscribe({
      next: (res) => {
        this.triggerDownload(res.data);
        this.commonService.toggleLoading(false)
        this.closeModal();
      }, error: (err) => {
        this.commonService.openErrorSnackBar(err.error.message, "")
        this.commonService.toggleLoading(false)
        this.closeModal();
      }
    }
    );
  }

  private triggerDownload(url: string): void {
    const link = document.createElement('a');
    link.href = url;
    // link.target = '_blank'; // Optional: opens in a new tab
    link.download = ''; // Optional: forces download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  sendEmail(storeId: number): void {
    this.commonService.toggleLoading(true)
    this.storeService.sendFlyerEmail(storeId).pipe(
    ).subscribe({
      next: (res) => {
        this.commonService.toggleLoading(false)
        this.closeModal();
        this.commonService.openSuccessSnackBar(res.message, "")
      }, error: (err) => {
        this.commonService.openErrorSnackBar(err.error.message, "")
        this.commonService.toggleLoading(false)
        this.closeModal();
      }
    }
    );
  }


}
